<script lang="ts" setup>
const { /* status, */ data } = useAuth();

defineEmits(["changeView"]);

// -----------------------
// vue events
// -----------------------
/*
onMounted(() => {
  // console.log(status.value);
  console.log(data.value);
});
 */

const redirectToEditProfile = async () => {
  const url = await $fetch(`/api/keycloak/editProfileUrl`);
  if (url) window.location.href = url;
};

const redirectToChangePassword = async () => {
  const url = await $fetch(`/api/keycloak/changePasswordUrl`);
  if (url) window.location.href = url;
};
</script>
<template>
  <div v-if="data?.user">
    <div class="flex flex-col md:flex-row">
      <div class="md:w-2/3 flex flex-col mb-5">
        <h2 class="text-3xl font-bold mb-2">
          {{ $t("profile.profile") }}
        </h2>

        <div class="flex flex-row">
          <div class="basis-1/2">{{ $t("name") }}</div>
          <div class="basis-1/2">
            {{ data.user.family_name }} {{ data.user.given_name }}
          </div>
        </div>
        <div class="flex flex-row">
          <div class="basis-1/2">{{ $t("email") }}</div>
          <div class="basis-1/2">{{ data.user.email }}</div>
        </div>
      </div>
      <div class="md:w-1/3">
        <BaseButton
          type="primary"
          class="w-full mb-5"
          @click="redirectToEditProfile"
          >{{ $t("profile.edit_profile") }}</BaseButton
        >
        <BaseButton
          type="primary"
          class="w-full"
          @click="redirectToChangePassword"
          >{{ $t("profile.change_password") }}</BaseButton
        >
      </div>
    </div>
  </div>
</template>
