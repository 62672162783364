<script lang="ts" setup>
const { logoutUser } = useHelpers();

const props = withDefaults(
  defineProps<{
    selected?: string | null;
  }>(),
  {
    selected: null,
  },
);

defineEmits(["changeView"]);

const logout = () => {
  logoutUser();
};

// -----------------------
// vue events
// -----------------------
/*
onMounted(() => {
  console.log(status.value);
  console.log(data.value);
});
 */
</script>
<template>
  <div class="cursor-pointer font-bold text-lg sm:text-xl">
    <div
      class="py-1"
      :class="props.selected === 'overview' ? 'text-brand-primary' : ''"
      @click="$emit('changeView', 'overview')"
    >
      {{ $t("profile.profile") }}
    </div>
    <div
      class="py-1"
      :class="props.selected === 'shippingData' ? 'text-brand-primary' : ''"
      @click="$emit('changeView', 'shippingData')"
    >
      {{ $t("profile.shipping_data") }}
    </div>
    <div
      class="py-1"
      :class="props.selected === 'billingData' ? 'text-brand-primary' : ''"
      @click="$emit('changeView', 'billingData')"
    >
      {{ $t("profile.billing_data") }}
    </div>
    <div
      class="py-1"
      :class="props.selected === 'orders' ? 'text-brand-primary' : ''"
      @click="$emit('changeView', 'orders')"
    >
      {{ $t("profile.orders") }}
    </div>
    <div class="py-1" @click="logout">
      {{ $t("profile.logout") }}
    </div>
  </div>
</template>
