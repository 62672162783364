<script lang="ts" setup>
const { $api } = useNuxtApp();

const { status } = useAuth();
const { logoutUser } = useHelpers();
const view = ref("overview");

const changeView = (newView: string) => {
  view.value = newView;
};

// -----------------------
// vue events
// -----------------------
// fixme: this needs to be moved to a middleware, not enough to check here if the API session still exists, the product lists etc should also refresh
onMounted(() => {
  if (status.value === "authenticated") {
    $api("user/check-token").catch(() => {
      logoutUser();
    });
  }
});
</script>

<template>
  <div v-if="status === 'authenticated'" class="container profile">
    <div class="flex flex-row mt-[30px]">
      <div class="basis-1/4">
        <ProfileMenu :selected="view" @change-view="changeView" />
      </div>
      <div class="basis-3/4 pt-2">
        <ProfileOverview v-if="view === 'overview'" @change-view="changeView" />
        <ProfileShippingData
          v-if="view === 'shippingData'"
          @change-view="changeView"
        />
        <ProfileBillingData
          v-if="view === 'billingData'"
          @change-view="changeView"
        />
        <ProfileNewBillingAddress
          v-if="view === 'newBillingAddress'"
          @change-view="changeView"
        />
        <ProfileNewShippingAddress
          v-if="view === 'newShippingAddress'"
          @change-view="changeView"
        />
        <ProfileOrders v-if="view === 'orders'" @change-view="changeView" />
      </div>
    </div>
  </div>
  <div v-else>
    <ProfileLoginOrRegister />
  </div>
</template>

<style></style>
