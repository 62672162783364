<script lang="ts" setup>
const props = defineProps<{
  index: number;
  address: Record<string, any>;
  type: string;
  makeDefaultTitle?: string | null;
}>();

const emit = defineEmits(["addressChanged"]);
const { $api } = useNuxtApp();

const hiddenRef = ref(true);

const editAddress = (formData: any) => {
  // console.log("editAddress", formData);

  $api("user/my-address", {
    method: "PATCH",
    body: formData,
  })
    .then(() => {
      hiddenRef.value = true;
      emit("addressChanged");
    })
    .catch(() => {
      // TODO: show an informative error message to user
    });
};

const deleteAddress = () => {
  $api(`user/my-address/${props.address.id}`, {
    method: "DELETE",
  })
    .then(() => {
      hiddenRef.value = true;
      emit("addressChanged");
    })
    .catch(() => {
      // TODO: show an informative error message to user
    });
};

/*
onMounted(() => {
  console.log(props.address);
});
*/
</script>
<template>
  <div class="shadow-md mb-3">
    <div
      class="flex flex-row cursor-pointer p-5"
      @mousedown.prevent="hiddenRef = !hiddenRef"
    >
      <div class="basis-5/6 font-bold text flex flex-row">
        <span
          >{{ props.index + 1 }}. {{ props.address.name }}
          <span v-if="props.address.pivot.is_default">
            ({{ $t("is_default") }})</span
          >
        </span>
      </div>
      <div class="basis-1/6 text-right">
        <CheckoutLargeArrow :inverted="hiddenRef" />
      </div>
    </div>
    <div :class="{ hidden: hiddenRef }" class="px-3 pb-3">
      <ProfileAddressForm
        mode="edit"
        :address="props.address"
        :type="props.type"
        :make-default-title="props.makeDefaultTitle"
        @form-submit="editAddress"
      />
      <BaseButton type="secondary" class="mt-3" @click.prevent="deleteAddress"
        >{{ $t("delete") }}
      </BaseButton>
    </div>
  </div>
</template>
